import { Block } from 'src/components/custom-dialog';

type Props = {
  label?: string;
  children?: any;
  className?: string;
  sx?: any;
};

export default function CasLabel({ label, children, className, sx={} }: Props) {
  if (label === undefined) return <>{children}</>;

  return <Block sx={{marginBottom: 2, ...sx }} 
                className={className}
                label={label as string}>
    {children}
    </Block>;
}
