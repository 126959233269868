import { Typography } from '@mui/material';
import CasLabel from '../CasLabel/CasLabel';

interface ICasDisplayField {
  label: string;
  value: string | JSX.Element;
  className?: string;
  horizontal?: boolean;
  fullWidth?: boolean;
}

export default function CasDisplayField({
  label,
  value,
  className,
  horizontal = false,
  fullWidth,
}: ICasDisplayField) {
  return (
    <CasLabel
      label={label}
      className={(horizontal ? 'cas-horizontal-field ' : '') + className}
      sx={fullWidth && { flex: 1 }}
    >
      <Typography>{value}</Typography>
    </CasLabel>
  );
}
